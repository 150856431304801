import { Turbo } from "@hotwired/turbo-rails"

document.addEventListener("turbo:frame-missing", event => {
  if (event.detail.response.redirected) {
    event.preventDefault()
    event.detail.visit(event.detail.response)
  }
})

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
}

// Fix for Trix Editor updates after tubro-frame reload
addEventListener("turbo:before-morph-attribute", (event) => {
  const { target } = event;

  if (target.tagName == "TRIX-EDITOR") {
    // save new value as default so that `reset()` doesn't reset to old content
    target.defaultValue = target.value;
  }
})

addEventListener("turbo:morph-element", (event) => {
  const { target } = event;

  if (target.tagName == "TRIX-EDITOR") {
    // after morphing `value` is `null`, so set value to default which was saved before
    target.reset();

    // disconnect because we call `connectedCallback()` below
    target.disconnectedCallback();

    // remove old `editorController`, otherwise `connectedCallback` will skip some initialization
    target.editorController = null;

    // connect it
    target.connectedCallback();
  }
})
