import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["shownByDefault", "hiddenByDefault"]

  show(event) {
    event.preventDefault()

    this.shownByDefaultTargets.forEach((element) => {
      element.classList.add("hidden")
    })
    this.hiddenByDefaultTargets.forEach((element) => {
      element.classList.remove("hidden")
    })
  }

  close(event) {
    event.preventDefault()

    this.shownByDefaultTargets.forEach((element) => {
      element.classList.remove("hidden")
    })
    this.hiddenByDefaultTargets.forEach((element) => {
      element.classList.add("hidden")
    })
  }
}
