import { Application } from "@hotwired/stimulus"

const app = Application.start()
app.debug = false

if (import.meta.env.RAILS_ENV === 'development') {
  app.debug = true
}

window.Stimulus = app

import { Alert, Dropdown, Modal } from "tailwindcss-stimulus-components"
app.register('alert', Alert)
app.register('dropdown', Dropdown)
app.register('modal', Modal)

import { registerControllers } from "stimulus-vite-helpers";

const controllers = import.meta.glob("../controllers/**/*_controller.js", { eager: true });
registerControllers(app, controllers);
