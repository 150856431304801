import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon", "menu"]

  toggle(event) {
    event.preventDefault()

    if (this.menuTarget.classList.contains("hidden")) {
      // Show the menu
      this.menuTarget.classList.remove("hidden")
      this.iconTarget.classList.add("fa-xmark")
      this.iconTarget.classList.remove("fa-bars")
    } else {
      // Hide the menu
      this.menuTarget.classList.add("hidden")
      this.iconTarget.classList.add("fa-bars")
      this.iconTarget.classList.remove("fa-xmark")
    }
  }

  close(event) {
    event.preventDefault()

    this.shownByDefaultTargets.forEach((element) => {
      element.classList.remove("hidden")
    })
    this.hiddenByDefaultTargets.forEach((element) => {
      element.classList.add("hidden")
    })
  }
}
