if (import.meta.env.RAILS_ENV === 'development') {
  console.log('Vite ⚡️ Rails')
}

// Load Rails libraries in Vite.

import "trix"
import "@rails/actiontext"

import "@fortawesome/fontawesome-free/js/all";

// Stimulus
import "../init/stimulus"

// Turbo
import { Turbo } from "@hotwired/turbo-rails"
Turbo.start()
import "../init/trix_fixes"

// ActiveStorage
import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
