import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mainImage", "previewImageLink"]

  show(event) {
    event.preventDefault()

    this.mainImageTarget.src = event.currentTarget.dataset.fullImageSrc
    this.previewImageLinkTargets.forEach((link) => {
      if (link.id === event.currentTarget.id) {
        link.classList.add("border-blue-500")
        link.classList.remove("border-gray-300")
      } else {
        link.classList.add("border-gray-300")
        link.classList.remove("border-blue-500")
      }
    })
  }
}
